import React, { useRef } from 'react'
import clamp from 'lodash-es/clamp'
import { useSprings, animated } from 'react-spring'
import { useGesture } from 'react-use-gesture'
import './Viewpager.css'

import chevron from '../assets/chevron.png';
import castroFront from '../assets/Canchas/castro1.JPG';
import castroBack from '../assets/Canchas/castro2.JPG';
import chacaFront from '../assets/Canchas/chaca1.JPG';
import chacaBack from '../assets/Canchas/chaca2.JPG';
import pirañaFront from '../assets/Canchas/piraña1.JPG';
import pirañaBack from '../assets/Canchas/piraña2.JPG';
import mitreFront from '../assets/Canchas/mitre1.jpeg';
import mitreBack from '../assets/Canchas/mitre2.jpeg';

import Card from './Card';

const pages = [
  {
    name: "Castro Barros",
    front: castroFront,
    back: castroBack,
    code: "boedo",
    address: "Av. Castro Barros 1261, Boedo, CABA",
    addressLink: "https://goo.gl/maps/jrKTckkgWtGia4jB7",
    app: false,
    appLink: "",
    text: <p className="frontInfoMain">
            • 2 Canchas de Fútbol 5 Cubiertas.
            <br/>
            • 1 Cancha de Fútbol 6 Descubierta.
            <br/>
            • Vestuarios.
            <br/>
            • Bar.
            <br/>
            • Estacionamiento.
            <br/>
            • Servicio de parrilla.
          </p>
  },
  {
    name: "Chacarita",
    front: chacaFront,
    back: chacaBack,
    code: "chacarita",
    address: "Bahía Blanca 2681, Villa Maipu, Buenos Aires",
    addressLink: "https://goo.gl/maps/aFtQs7Cmbvfxuzbo6",
    app: true,
    appLink: "https://alquilatucancha.com/sportclub/164",
    text: <p className="frontInfoMain">
            • 4 Canchas de Fútbol 5.
            <br/>
            • 1 Cancha de Fútbol 8.
            <br/>
            • Vestuarios.
            <br/>
            • Bar.
            <br/>
            • Estacionamiento.
            <br/>
            • Servicio de parrilla.
          </p>
  },
  {
    name: "Club Atlético Piraña",
    front: pirañaFront,
    back: pirañaBack,
    code: "piraña",
    address: "Elia 678, Parque Patricios, CABA",
    addressLink: "https://goo.gl/maps/VVZvD8mETMrfsRJQ9",
    app: true,
    appLink: "https://alquilatucancha.com/sportclub/163",
    text: <p className="frontInfoMain">
            • 6 Canchas de Fútbol 5.
            <br/>
            • 2 Cancha de Fútbol 7.
            <br/>
            • Vestuarios.
            <br/>
            • Bar.
            <br/>
            • Servicio de parrilla.
          </p>
  },
  {
    name: "Club Mitre",
    front: mitreFront,
    back: mitreBack,
    code: "mitre",
    address: "Gral. N. Manuel Savio 3020, Villa Maipu, Buenos Aires",
    addressLink: "https://goo.gl/maps/mNeutVw1W8CBBrUS8",
    app: false,
    appLink: "",
    text: <p className="frontInfoMain">
            • 7 Canchas de Fútbol 5.
            <br/>
            • 1 Cancha de Fútbol 9.
            <br/>
            • Vestuarios.
            <br/>
            • Bar.
          </p>
  }
]

export function Viewpager(props) {
  const index = useRef(0)
  
  const [styleProps, set] = useSprings(pages.length, i => ({ x: i * window.innerWidth, sc: 1, display: 'block' }))
  
  const bind = useGesture(({ down, delta: [xDelta], direction: [xDir], distance, cancel }) => {
    if (down && distance > window.innerWidth / 2)
      cancel((index.current = clamp(index.current + (xDir > 0 ? -1 : 1), 0, pages.length - 1)))
      set(i => {
        if (i < index.current - 1 || i > index.current + 1) return { display: 'none' }
        const x = (i - index.current) * window.innerWidth + (down ? xDelta : 0)
        const sc = down ? 1 - distance / window.innerWidth / 2 : 1
        return { x, sc, display: 'block' }
      })
  })

  const nextImage = () => {
    index.current = clamp(index.current + 1, 0, pages.length - 1);
    set(i => {
      if (i < index.current - 1 || i > index.current + 1) return { display: 'none' }
      const x = (i - index.current) * window.innerWidth + 0
      const sc = 1
      return { x, sc, display: 'block' }
    })
  }

  const prevImage = () => {
    index.current = clamp(index.current - 1, 0, pages.length - 1);
    set(i => {
      if (i < index.current - 1 || i > index.current + 1) return { display: 'none' }
      const x = (i - index.current) * window.innerWidth + 0
      const sc = 1
      return { x, sc, display: 'block' }
    })
  }

  return (
    <div className="subContainer">
      <img src={chevron} onClick={nextImage} className="chevronRight" alt="Chevron Derecho" />
      <img src={chevron} onClick={prevImage} className="chevronLeft" alt="Chevron Izquierdo" />
      {
        styleProps.map(({ x, display, sc }, i) => (
          <animated.div {...bind()} key={i} style={{ display, transform: x.interpolate(x => `translate3d(${x}px,0,0)`) }}>
            <animated.div style={{ transform: sc.interpolate(s => `scale(${s})`) }}>
              <Card data={pages[i]} bookField={props.bookField} />
            </animated.div>
          </animated.div>
        ))
      }
    </div>
  )
}
