import React, { useState } from 'react';
import { useSpring, animated as a } from 'react-spring';

import './Card.css';

import alquilaLogo from '../assets/alquilaLogo.png';

export default function Card(props) {
  const [flipped, set] = useState(false)
  const { transform, opacity } = useSpring({
    opacity: flipped ? 1 : 0,
    transform: `perspective(600px) rotateX(${flipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 }
  })

  const goToBook = (e) => {
    e.stopPropagation();
    if (props.data.app) {
      window.open(props.data.appLink);
    } else {
      props.bookField(props.data.code);
    }
  }

  const handleAddressClick = (e) => {
    e.stopPropagation();
    window.open(props.data.addressLink);
  }

  const turnCard = (e) => {
    e.stopPropagation();
    set(state => !state);
  }

  return (
    <div className="cardContainer" onClick={(e) => turnCard(e)}>
      <a.div className="c back" style={{ opacity: opacity.interpolate(o => 1 - o), transform }}>
        <img src={props.data.front} alt={props.data.name} className="backImg" />
        <p className="backTitle">{props.data.name}</p>
      </a.div>
      <a.div className="c front" style={{ opacity, transform: transform.interpolate(t => `${t} rotateX(180deg)`) }}>
        <img src={props.data.back} alt={props.data.name} className="frontImg" />
        <div className="frontInfoContainer">
          <p className="frontInfoTitle">{props.data.name}</p>
          <p className="frontInfoAddress" style={{ pointerEvents: flipped ? 'all' : 'none' }} onClick={(e) => handleAddressClick(e)}>{props.data.address}</p>
          {props.data.text}
          <p className="frontInfoCTA" style={{ pointerEvents: flipped ? 'all' : 'none' }} onClick={(e) => goToBook(e)}>
            { props.data.app ? <span><img src={alquilaLogo} className="alquilaApp" alt="Logo Alquila tu cancha App" /></span> : null}
            Reservá ahora
          </p>
        </div>
      </a.div>
    </div>
  )
}