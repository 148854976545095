import React, { useState, useRef, useEffect } from 'react';
import {useSpring, useChain, animated} from 'react-spring';
import Lottie from 'react-lottie';
import * as animationData from './assets/34-scroll-indicator.json';
import * as loaderAnimData from './assets/271-loader.json';
import { Element, scroller } from 'react-scroll';
import axios from 'axios';

import backgroundImg from './assets/background2.png';
import foregroundImg from './assets/foreground2.png';
import logoImg from './assets/logoWhite.png';
import crowd from './assets/crowdCropped.png';
import pibito from './assets/pibitoCropped.png';
import footballPitch from './assets/footballPitch.png';
import fifaLogo from './assets/fifaLogo.png';
import forbexLogo from './assets/forbexLogo.png';
//import seImg from './assets/se.png';
//import diferenteImg from './assets/diferente.png';
import tick from './assets/tick.png';
import serFutbol from './assets/serFutbol2.png';
import instagramLogo from './assets/instagramLogo.png';
import seDiferente from './assets/seDiferente.png';
import contactBg from './assets/contactBg3.png';
import phoneIcon from './assets/phoneIcon.png';
//import logoFooter from './assets/logoFooter.png';

import { Viewpager } from './components/Viewpager';

import './App.css';

export default function App() {
  const [bgLoaded, setBgLoad] = useState(false);
  const [fgLoaded, setFgLoad] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState("");
  const [message, setMessage] = useState("");

  const [buttonStatus, setButtonStatus] = useState(null);
  const [buttonText, setButtonText] = useState("Enviar");

  const [scrollPosition, setScroll] = useState(0);

  const overlaySpringRef = useRef();
  const overlayProps = useSpring({
    ref: overlaySpringRef,
    from: { opacity: 1, pointerEvents: 'all' },
    to: { opacity: bgLoaded && fgLoaded ? 0 : 1, pointerEvents: bgLoaded && fgLoaded ? 'none' : 'all' }
  })

  const serFutbolSpringRef = useRef();
  const serFutbolProps = useSpring({
    ref: serFutbolSpringRef,
    from: { opacity: 1, top: '50%' },
    to: { opacity: bgLoaded && fgLoaded ? 0 : 1, top: bgLoaded && fgLoaded ? '60%' : '50%' },
    config: { delay: 3000 }
  })

  const headingSpringRef = useRef();
  const headingProps = useSpring({
    ref: headingSpringRef,
    from: { opacity: 0, marginTop: 'calc(10vh)' },
    to: { opacity: bgLoaded && fgLoaded ? 1 : 0, marginTop: bgLoaded && fgLoaded ? 'calc(0vh)' : 'calc(10vh)' }
  })

  useChain([{current: overlaySpringRef.current}, serFutbolSpringRef, headingSpringRef])

  const playerProps = useSpring({
    from: { transform: 'scale(1)' },
    to: { transform: scrollPosition < 1600 ? 'scale(1)' : scrollPosition > 2200 ? 'scale(1.2)' : 'scale(' + Math.abs(1 + (scrollPosition - 1600) / 3000) + ')' }
  })

  const formButtonProps = useSpring({
    from: { backgroundColor: '#016766' },
    to: { backgroundColor: buttonStatus === "sending" ? '#2196f3' : buttonStatus === "ok" ? '#63d176' : buttonStatus === 'error' ? '#fc3d39' : '#016766' }
  })

  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const loaderOptions = {
    loop: true,
    autoplay: true, 
    animationData: loaderAnimData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const goToContact = () => {
    scroller.scrollTo('contact', {
      duration: 500,
      delay: 0,
      smooth: true,
    });
  }

  const goToFields = () => {
    scroller.scrollTo('fields', {
      duration: 500,
      delay: 0,
      smooth: true,
    });
  }

  const bookField = (fieldCode) => {
    setLocation(fieldCode);
    goToContact();
  }

  const onScroll = () => {
    setScroll(window.scrollY);
  }

  const handleSubmit = () => {
    if (buttonStatus === "ok" || buttonStatus === "error") {
      setButtonStatus(null);
      setButtonText("Enviar");
      setName("");
      setEmail("");
      setLocation("");
      setMessage("");
    } else {
      setButtonStatus('sending');
      setButtonText("Enviando...");

      axios({
        method:'post',
        url: "https://us-central1-ser-futbol-web.cloudfunctions.net/sendEmail",
        data: {
          name: name,
          email: email,
          location: location,
          message: message
        }
        })
          .then((response) => {
            setButtonStatus('ok');
            setButtonText("Enviado!");
          })
          .catch((error) => {
            //console.log(error);
            setButtonStatus('error');
            setButtonText("Error");
          });
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    }
  })

  return (
    <div className="mainApp">
      <animated.div style={overlayProps} className="loaderOverlay">
        <div className="spinnerContainer">
          <Lottie options={loaderOptions}
            height={'calc(5vw)'}
            width={'calc(5vw)'}
          />
        </div>
      </animated.div>
      <animated.img src={serFutbol} style={serFutbolProps} className="serFutbolLoader" alt="Logo Ser Fútbol" /> 
      <img src={logoImg} className="logo" alt="Logo" />
      <img src={backgroundImg} onLoad={() => setBgLoad(true)} className="background" alt="Background" />
      <img src={foregroundImg} onLoad={() => setFgLoad(true)} className="foreground" alt="Foreground" />
      {/*<div className="heading">
        <animated.img src={seImg} style={headingProps} alt="Sé" className="headingFirst" />
        <animated.img src={diferenteImg} style={headingProps} alt="Diferente" className="headingSecond" />
      </div>*/}
      <animated.img src={seDiferente} style={headingProps} alt="Sé Diferente" className="headingMain" />
      <div className="cta" onClick={goToFields}>Reservá tu cancha</div>
      <div className="scrollIconContainer">
        <Lottie options={defaultOptions}
          height={400}
          width={400}
        />
      </div>
      <a className="instagramLink" href="https://www.instagram.com/serfutbol_/" target="_blank" rel="noopener noreferrer"><img src={instagramLogo} style={{height: "100%"}} alt="Logo Instagram"/></a>
        
      <Element name="fields">
        <div className="fieldsContainer">
          <div className="fieldsAuxContainer">
            <div className="fieldsTitle">Nuestros<br/><span className="fieldsTitleContrast">Complejos</span></div>
            <div className="fieldsSubtitle">Toca en las fotos para <br/>ver más información</div>
          </div>
          <div className="mainContainer">
            <Viewpager bookField={bookField}/>
          </div>
        </div>
      </Element>


      <img src={crowd} className="crowdImg" alt="Hinchada alentando a su equipo de fútbol" />
        

      <div className="aboutContainer">
        <animated.img src={pibito} style={playerProps} className="aboutImg" alt="Jugador de futbol pateando una pelota" />
        <div className="aboutTextContainer">
          <p className="aboutTextTitle">¿Por qué <span className="aboutTextTitleContrast">nosotros?</span></p>
          <p className="aboutText">
            <span><img src={tick} className="aboutTick" alt="OK" /></span>Porque tenemos las mejores canchas, con la más alta tecnología.
          </p>
          <p className="aboutText">
            <span><img src={tick} className="aboutTick" alt="OK" /></span>Porque tenemos complejos en todo Capital Federal, siempre cerca tuyo.
          </p>
          <p className="aboutText">
            <span><img src={tick} className="aboutTick" alt="OK" /></span>Porque ofrecemos una experiencia completa, con bar y servicio de parrilla en todas nuestras canchas.
          </p>
          <p className="aboutTextSwitch">
            Porque Somos Fútbol, y el futbolero lo entiende.
          </p>
        </div>
      </div>

      <div className="grassContainer">
        <img src={footballPitch} className="grassImg" alt="Football pitch background" />
        <p className="grassText">Nuestras canchas cuentan con cesped sintético Stadium de la empresa Forbex, con tecnología homologada por la FIFA para competición y entrenamiento de alta performance.</p>
        <div className="logosContainer">
          <img src={forbexLogo} className="grassLogo" alt="Forbex logo" />
          <img src={fifaLogo} className="grassLogo" alt="Fifa logo" />
        </div>
      </div>

      <div className="contactContainer">
        <Element name="contact">
          <div className="contactInfo">
            <img src={contactBg} alt="Panel de contacto" className="contactBg" /> 
            <div className="contactInfoRow firstRow">
              <a className="contactInstagram" href="tel:52633880" target="_blank" rel="noopener noreferrer">
                <img src={phoneIcon} style={{height: "100%"}} alt="Icono Telefono Sonando"/>
                <p className="instagramName altInstagramName">5263-3880</p>
              </a>
            </div>
            <div className="contactInfoRow">
              <a className="contactInstagram" href="https://www.instagram.com/serfutbol_/" target="_blank" rel="noopener noreferrer">
                <img src={instagramLogo} style={{height: "100%"}} alt="Logo Instagram"/>
                <p className="instagramName">serfutbol_</p>
              </a>
            </div>
          </div>
        </Element>
        <div className="formContainer">
          <p className="formAux">Dejanos tu mensaje y uno de nuestros asesores se comunicará con vos a la brevedad.</p>
          <input type="text" className="formInputSimple" placeholder="Nombre" value={name} onChange={(e) => setName(e.target.value)} />
          <input type="text" className="formInputSimple" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
          <select placeholder="Sede" value={location} onChange={(e) => setLocation(e.target.value)} className="formInputSimple formInputNoPlaceholder">
            <option disabled={true} value="">Sede</option>
            <option value="boedo">Castro Barros</option>
            <option value="chacarita">Chacarita</option>
            <option value="piraña">Club Atlético Piraña</option>
            <option value="mitre">Club Mitre</option>
          </select>
          <textarea className="formTextArea" placeholder="Mensaje" value={message} onChange={(e) => setMessage(e.target.value)} />
          <animated.button type="button" className="formButton" onClick={handleSubmit} style={formButtonProps} disabled={name === "" || email === "" || location === "" || message === ""}>{buttonText}</animated.button>
        </div>
      </div>

      {/*<div className="footer">
          <p className="footerText footerLeft">2019 © SerFútbol</p>
          <div className="logoFooterContainer footerRight">
            <p className="footerText designedBy">Diseñado por</p>
            <a className="logoFooter" href="https://www.estudioanima.com" target="_blank" rel="noopener noreferrer"><img src={logoFooter} style={{height: "100%"}} alt="Logo Anima"/></a>
          </div>
        </div>*/}
    </div>
  );
}